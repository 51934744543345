<!-- eslint-disable vue/html-self-closing -->
<template>
  <div v-if="!requireContext || context " class="d-flex flex-wrap px-4">
    <div class="breadcrumbs d-flex pa-0 col-12 content-full-h">
      <div>
        <v-btn
          class="mt-3 mb-4 mr-2"
          fab
          dark
          x-small
          color="primary"
          @click="changeSideBar()"
        >
          <v-icon dark>
            {{ !showFacets ? "mdi-forwardburger" : "mdi-backburger" }}
          </v-icon>
        </v-btn>
        <v-expand-x-transition>
          <div v-show="showFacets" class="sidebar">
            <Facets v-if="facetsConfig" :facets-values="facetValues" :facets-config="facetsConfig" :column-height="facetbarHeight - 0" @filtersUpdate="onFiltersUpdate" />
          </div>
        </v-expand-x-transition>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex mt-3 pr-3 pl-3 flex-wrap breadcrumbs-bar">
          <Breadcrumbs :facets="facetsShown" :filters="filters" @removeFilter="removeFilter" @clearFilters="clearFilters" />
        </div>
        <div class="d-flex flex-grow-1 pt-0 pl-0" style="max-height: 90%">
          <div class="d-flex flex-column pa-2 my-4 ml-3 mr-0 col-6 white-card">
            <small>Participants</small>
            <div class="mt-2">
              <div class="ml-4">
                <a v-if="totalPages > 1 && fullPageSelected && !allItemsSelected" class="text-decoration-underline" @click="toggleSelectAll">Sélectionnez les {{ totalItems - ignoreParticipants.length }} participants</a>
                <a v-if="totalPages > 1 && fullPageSelected && allItemsSelected" class="text-decoration-underline" @click="toggleSelectAll">Sélectionnez uniquement les participants à cette page</a>
                &nbsp;
              </div>
              <v-data-table
                v-model="selectedParticipants"
                class="mt-1 flex-grow-1"
                show-select
                multi-sort
                :loading="loading"
                loading-text="En cours..."
                :options.sync="tableOptions"
                :server-items-length="participants.length"
                :headers="participantsHeaders"
                fixed-header
                height="57vh"
                item-key="key"
                :items="participants"
                hide-default-footer
                dense
                @item-selected="itemSelected"
              >
                <template #[`header.data-table-select`]="{ on , props }">
                  <v-simple-checkbox
                    color="red"
                    v-bind="props"
                    v-on="on"
                    @input="fullPageSelectedToggle"
                  />
                </template>
                <template #[`item.gender`]="{ item }">
                  {{ item.gender === 'mr' ? 'M' :
                    item.gender === 'mme' ? 'Mme' :
                    item.gender === null ? "n/a" : "autre" }}
                </template>
              </v-data-table>
          </div>
            <div v-if="participants.length > 0" class="d-flex ma-0 pa-0 justify-center">
              <div class="col-8 ma-0 pa-0">
                <v-pagination v-model="tableOptions.page" :length="totalPages" :total-visible="tableOptions.itemsPerPage" class="pb-1 pt-2 d-flex" />
                <div class="d-flex justify-center align-center">
                  <small class="d-flex pb-2 mx-2">Lignes par page</small>
                  <v-select
                    v-model="tableOptions.itemsPerPage"
                    class="mx-2 my-0 max-width-50px"
                    :items="[25, 50, 100]"
                    dense
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column pt-0 col-6">
            <v-card class="d-flex justify-start flex-column pa-2 my-4">
              <small>Volume de participants séléctionné</small>
              <div class="text-center text-h4 font-weight-bold">{{ allItemsSelected ? totalItems - ignoreParticipants.length : selectedParticipants.length }}</div>
            </v-card>
            <v-card class="d-flex flex-column pa-2 mb-1 grow">
              <small class="mb-3">Communication</small>
              <p class="text-center body-2 mb-0">Rédigez ici le message que vous souhaitez envoyer. Tous les clients sélectionnés recevront le même message. Assurez-vous que le contenu soit approprié et pertinent pour l’ensemble des destinataires. Le SMS doit être utilisé uniquement en cas d’urgence. Une fois le bouton “Envoyer” cliqué, il sera envoyé simultanément à tous et l’envoi est définitif.</p>
              <div v-show="isChatLoaded" class="flex w-full grow align-items-center">
                <iframe
                  id="iframeChat"
                  ref="chatIframe"
                  style="border: none"
                  height="100%"
                  width="100%"
                  :src="chatFrameUrl"
                  @load="onChatLoad"
                ></iframe>
              </div>
              <div v-if="!isChatLoaded" class="d-flex w-full grow align-center justify-center">
                <v-progress-circular indeterminate color="red" :size="100"></v-progress-circular>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <v-card class="ma-6" color="warning" rounded="lg" outlined elevation="4">
      <v-card-text class="white--text text-h6">
        <div>
          Un contexte doit être sélectionné.
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import Facets from '../components/Facets';
import { getTargetingConfig } from '@/configs/helper';
import store from '@/store/index';
import transformFilters from '@/utils/facets';
import { requests } from '../utils/requests';
import Breadcrumbs from '../components/Breadcrumbs.vue';

const config = require('@/configs/targetingFacets.json');

const workspace = computed(() => store.getters.getActiveBrand);

const context = computed(() => store.getters.getActiveContext);

const destination = computed(() => store.getters.getActiveDestination);

const requireContext = computed(() => !!(config[workspace.value].requireContext));

const facetsConfig = computed(() => (requireContext.value ? getTargetingConfig(context.value) : getTargetingConfig(workspace.value)));

const filters = ref([]);

const facetsShown = ref([]);

const showFacets = ref(true);

const facetValues = ref({});

const loading = ref(false);

const participants = ref([]);

const selectedParticipants = ref([]);

const ignoreParticipants = ref([]);

const fullPageSelected = ref(false);

const allItemsSelected = ref(false);

const chatIframe = ref(null);
const chatFrameUrl = computed(() => `${process.env.VUE_APP_ENVIROMENT === 'local' ? 'http://localhost:7070' : store.getters.getBrandUrl}/targeting?from=${window.location.href}&token=${store.state.token}`);
const isChatLoaded = ref(false);
const onChatLoad = () => {
  isChatLoaded.value = true;
};

// iframe chat handling
const postToIframe = (data, iframe) => {
  iframe.contentWindow.postMessage(data, '*');
};

// Participants Table
const participantsHeaders = [
  { text: 'Civilité', value: 'gender', sortable: false },
  { text: 'Nom', value: 'lastName', sortable: true },
  { text: 'Prénom', value: 'firstName', sortable: true }
];

// Pagination
const tableOptions = ref({ itemsPerPage: 25 });
const totalItems = ref(0);
const totalPages = computed(() => {
  if (totalItems.value) {
    return Math.ceil(totalItems.value / tableOptions.value.itemsPerPage);
  }
  return 1;
});
const sort = computed(() => {
  const sortArray = [];
  const { sortBy, sortDesc } = tableOptions.value;

  if (sortBy.length) {
    for (let i = 0; i < sortBy.length; i += 1) {
      sortArray.push({
        field: `participant.${sortBy[i]}.raw`,
        direction: sortDesc[i] ? 'desc' : 'asc'
      });
    }
  }
  return sortArray;
});

let abortController = null;

const facetbarHeight = 285;

const fullPageSelectedToggle = value => {
  ignoreParticipants.value = [];
  if (!value) {
    allItemsSelected.value = false;
  }
  fullPageSelected.value = value;
};

const toggleSelectAll = () => {
  allItemsSelected.value = !allItemsSelected.value;

  if (!allItemsSelected.value) selectedParticipants.value = participants.value;
};

const itemSelected = item => {
  if (!item.value) {
    ignoreParticipants.value.push(item.item);
  }
};

const changeSideBar = () => showFacets.value = !showFacets.value;

const getFacetAggregates = () => {
  const facetAggregates = [];

  Object.entries(facetsConfig.value).forEach(([key, facet]) => {
    if (facet.aggregated) {
      facetAggregates.push({ field: facet.filterField, name: key, type: facet.aggregated });
    }
    if (facet.type === 'multiField') {
      facet.values.forEach(v => {
        facetAggregates.push({ field: v.value, name: `${key}-${v.value}`, type: 'terms' });
      });
    }
  });

  // include destinations name facet
  facetAggregates.push({ field: 'destination.name.raw', name: 'destination', type: 'terms' });

  return facetAggregates.length ? facetAggregates : null;
};

const updateRequestFacets = aggregations => {
  if (aggregations) {
    aggregations.forEach(aggregate => {
      if (facetValues.value && facetValues.value[aggregate.field]) {
        const facetCfg = facetsConfig.value[aggregate.field];
        if (facetCfg.values && facetCfg.values.length) {
          facetValues.value[aggregate.field].data = facetValues.value[aggregate.field].data.map(value => {
            const result = aggregate.results.find(r => r.key_as_string === value.value_string);
            if (result) {
              return { ...value, count: result.doc_count };
            }
            return value;
          });
        } else {
          facetValues.value[aggregate.field].data = aggregate.results.map(r => ({
            label: r.key,
            count: r.doc_count,
            value: r.key
          }));
        }
      } else {
        const splitField = aggregate.field.split('-');
        const facetField = facetValues.value[splitField[0]];

        if (facetValues.value && facetField && facetField.type === 'multiField') {
          const aggregateResult = aggregate.results.find(r => r.key_as_string === 'true');
          const facetIndex = facetField.data.findIndex(item => item.value === splitField[1]);
          if (facetIndex !== -1 && aggregateResult) {
            const facetData = [...facetField.data];
            facetData[facetIndex].count = aggregateResult.doc_count;

            facetField.data = facetData;
          }
        }
      }
      return true;
    });
  }
};

const updateDestinations = aggregations => {
  const destinationsAggregate = aggregations.find(a => a.field === 'destination');

  store.commit('setDestinationOptions', destinationsAggregate ? destinationsAggregate.results : []);
};

const getTransformedFilters = () => {
  const transformedFilters = [];

  Object.entries(facetsConfig.value).forEach(([field, facet]) => {
    const filterValue = filters.value.find(f => f.field === field)?.value;
    const filter = transformFilters(facet, filterValue);
    if (filter) {
      transformedFilters.push(...filter);
    }
  });

  if (destination.value) {
    transformedFilters.push({
      or: {
        condition: { eq: destination.value },
        field: 'destination.name.raw'
      }
    });
  }

  return transformedFilters;
};

const getParticipants = async () => {
  if (abortController) {
    abortController.abort('new request has been made.');
  }
  loading.value = true;
  const transformedFilters = getTransformedFilters();

  const facets = getFacetAggregates();

  abortController = new AbortController();
  const { signal } = abortController;
  const { page, itemsPerPage } = tableOptions.value;

  const queryOptions = {
    filter: transformedFilters.length ? { and: transformedFilters } : null,
    facets: facets,
    from: (page - 1) * itemsPerPage,
    limit: itemsPerPage,
    sort: sort.value
  };
  const res = await requests.getParticipants(queryOptions, signal)
    .catch(err => {
      console.error(err);
    });

  if (!res || !res.success || !res.data) {
    loading.value = false;
    return false;
  }

  const { aggregations, items, total } = res.data;
  totalItems.value = total;

  // eslint-disable-next-line no-underscore-dangle
  participants.value = items.filter(item => item.participant.firstName !== null || item.participant.lastName !== null).map(item => ({ ...item.participant, key: item._id }));

  if (allItemsSelected.value) {
    selectedParticipants.value.push(...participants.value.filter(p => !selectedParticipants.value.some(pa => pa.key === p.key) && !ignoreParticipants.value.some(ip => ip.uuid === p.uuid)));
  }
  updateRequestFacets(aggregations);
  updateDestinations(aggregations);

  loading.value = false;
  return true;
};

const configureFacetValues = () => {
  const values = {};
  Object.entries(facetsConfig.value).forEach(([name, facet]) => {
    if (facet.aggregated) {
      values[name] = { data: [], type: facet.type, count: 0 };
    }
    if (facet.values) {
      values[name] = { data: facet.values, type: facet.type, count: 0 };
    }
  });
  facetValues.value = values;
};

const onFiltersUpdate = async value => {
  filters.value = value.filters;
  facetsShown.value = value.facetsShown.flatMap(facet => facet);
  await getParticipants();

  if (allItemsSelected.value) {
    const transformedFilters = getTransformedFilters();
    postToIframe({ selectAll: true, ignoreUsers: ignoreParticipants.value, filters: transformedFilters.length ? { and: transformedFilters } : null }, chatIframe.value);
  }
};

watch(destination, async () => {
  await getParticipants();
});

const removeFilter = (field, value) => {
  const filterIdx = filters.value.findIndex(f => f.field === field);
  if (filterIdx >= 0) {
    if (facetsConfig.value[field]?.type === 'multiValue') {
      const idx = filters.value[filterIdx].value.findIndex(v => v === value);
      filters.value[filterIdx].value.splice(idx, 1);
      if (!filters.value[filterIdx].value.length) {
        filters.value.splice(filterIdx, 1);
      }
    } else {
      filters.value.splice(filterIdx, 1);
    }
  }
};

const clearFilters = () => {
  filters.value.splice(0);
};

watch(selectedParticipants, newValue => {
  const transformedFilters = getTransformedFilters();
  postToIframe({ users: newValue, selectAll: allItemsSelected.value, ignoreUsers: ignoreParticipants.value, filters: transformedFilters.length ? { and: transformedFilters } : null }, chatIframe.value);
});

watch(allItemsSelected, newValue => {
  const transformedFilters = getTransformedFilters();
  postToIframe({ selectAll: newValue, ignoreUsers: ignoreParticipants.value, filters: transformedFilters.length ? { and: transformedFilters } : null }, chatIframe.value);
});

onMounted(async () => {
  if (!requireContext.value || context.value) {
    configureFacetValues();
  }
});

watch(tableOptions, async () => {
  await getParticipants();
}, { deep: true });
</script>

<style lang="scss">
.white-card {
  background-color: white;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.breadcrumbs {
  min-height: 40px;
}

.breadcrumbs-bar {
  height: 10%;
  overflow-y: auto;
}

.sidebar {
  width: 300px;
  height: calc(100vh - 185px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.content {
  width: calc(100% - 300px);
  height: calc(100vh - 140px);
}
.results {
  max-height: calc(100vh - 275px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.content-full-h {
  height: calc(100vh - 112px);
}

.max-width-50px {
  max-width: 50px
}

</style>
