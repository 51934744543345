import { addDayToDate } from '../configs/helper';

const getBirthDate = age => {
  const today = new Date();
  today.setFullYear(today.getFullYear() - age);
  return today.toISOString();
};

const getFilterValue = (value, facet) => {
  if (facet.dataType === 'age') return getBirthDate(value);
  return value;
};

const transformFilters = (facet, filterValue) => {
  let filter = [];
  if (facet.type === 'multiValue') {
    if (filterValue && filterValue.length) {
      filter = [{
        or: filterValue.map(f => {
          const conditionObject = { field: facet.filterField };
          conditionObject.condition = {};
          conditionObject.condition[facet.filterCondition || 'eq'] = encodeURIComponent(f);
          return conditionObject;
        })
      }];
    }
  } else if (facet.type === 'multiField') {
    if (filterValue && filterValue.length) {
      filter = [{
        or: filterValue.map(f => {
          const conditionObject = { field: f };
          conditionObject.condition = {};
          conditionObject.condition[facet.filterCondition || 'eq'] = true;
          return conditionObject;
        })
      }];
    }
  } else if (facet.component === 'date' || facet.type === 'range') {
    if (filterValue && Array.isArray(filterValue) && filterValue.length > 1) {
      filter = [
        { field: facet.filterField, condition: { gte: `${getFilterValue(filterValue[0], facet)}` } },
        { field: facet.filterField, condition: { lte: `${getFilterValue(filterValue[1], facet)}` } }
      ];
    } else if (filterValue && facet.component === 'date') {
      const nextDay = addDayToDate(filterValue);
      filter = [
        { field: facet.filterField, condition: { gte: filterValue } },
        { field: facet.filterField, condition: { lt: nextDay } }
      ];
    }
  } else if (filterValue || filterValue === false) {
    filter = [{ field: facet.filterField }];
    filter[0][facet.conditionType || 'condition'] = {};
    filter[0][facet.conditionType || 'condition'][facet.filterCondition || 'eq'] = filterValue;
  }
  return filter;
};

export default transformFilters;
